<template>
  <section class="landing-nsk-desktop">
    <div class="landing-nsk-desktop_text-block">
      <img
        src="~/assets/img/svg/select-city/logo.svg"
        alt="логотип компании"
      >
      <p class="landing-nsk-desktop_desc">
        С 2005 года строим удобные и надежные дома по новейшим мировым технологиям.
      </p>
      <p class="landing-nsk-desktop_choose">
        Выбери город <br>
        и обратись в отдел продаж
      </p>
    </div>
    <div class="landing-nsk-desktop_city-block">
      <div
        v-for="item in citiesList"
        :key="item.id"
        class="landing-nsk-desktop_city-item"
      >
        <div
          :class="`city__${item.id}`"
          class="city-item_title"
          @click="clickHandler(item)"
        >
          {{ item.city }}
        </div>
      </div>
    </div>

    <div
      v-if="currentItem"
      class="v-big-close-block"
      @click="closeModal"
    ></div>
    <div
      v-if="currentItem"
      class="v-hero-desktop_close"
      @click="closeModal"
    >
      <img
        src="~/assets/img/svg/select-city/close.svg"
        alt="close icon"
      >
    </div>
    <div
      v-if="currentItem"
      class="landing-nsk-desktop_modal"
    >
      <div class="modal_text-block">
        <div class="modal_head-block">
          <img
            class="modal_head-img"
            src="~/assets/img/svg/select-city/logo-dark.svg"
            alt="логотип компании"
          >

          <div class="modal_head-city">
            {{ currentItem.title ? currentItem.title : currentItem.city }}
          </div>
        </div>

        <div class="modal_title-block">
          <h3
            class="modal_title"
            v-html="currentItem.modalTitle"
          ></h3>
        </div>

        <div
          v-if="currentItem.address"
          class="modal_item"
        >
          <h4 class="modal_item-title">Адрес</h4>
          <p
            class="modal_item-text"
            v-html="currentItem.address"
          ></p>
        </div>
        <div
          v-if="currentItem.time"
          class="modal_item"
        >
          <h4 class="modal_item-title">График работы</h4>
          <p
            v-for="(item, i) in currentItem.time"
            :key="i"
            class="modal_item-text"
            v-html="item"
          ></p>
        </div>
        <div
          v-if="currentItem.phone"
          class="modal_item"
        >
          <h4 class="modal_item-title">Телефон</h4>
          <p
            v-for="(item, index) in currentItem.phone"
            :key="index"
            class="modal_item-text"
          >
            <a :href="`tel: ${item.phone}`">{{ item.phone }}</a>
            <span v-if="item.desc">{{ item.desc }}</span>
          </p>
        </div>
        <div
          v-if="currentItem.email"
          class="modal_item"
        >
          <h4 class="modal_item-title">E-mail</h4>
          <p
            v-for="(item, index) in currentItem.email"
            :key="index"
            class="modal_item-text"
          >
            <a :href="`mailto: ${item.email}`">{{ item.email }}</a>
            <span v-if="item.desc">{{ item.desc }}</span>
          </p>
        </div>
        <div class="modal_item">
          <h4 class="modal_item-title">Астон.Клик</h4>
          <div class="modal_item-text">
            <a
              href="https://aston.click/"
              target="_blank"
              class="modal_item-link"
            >
              aston.click
            </a>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>

<script setup lang="ts">
import type { CitySelectionStubBlockType } from '~/cms/blocks/CitySelectionStubBlock.vue';
import selectCity from "~/utils/selectCity/selectCity";

const props = defineProps<{
	citiesList: CitySelectionStubBlockType[];
}>();

const currentItem = ref<CitySelectionStubBlockType | null>(null);

const clickHandler = (city: CitySelectionStubBlockType) => {
  currentItem.value = selectCity(city, currentItem.value);
};

const closeModal = () => {
  currentItem.value = null;
};

const initData = () => {
  // Проверка на наличии HEADER
  const header = document.querySelector('header');
  if (header) {
    header.style.display = 'none';
  }

  // Проверка на наличии FOOTER
  const footer = document.querySelector('footer');
  if (footer) {
    footer.style.display = 'none';
  }

  const fullPath = window.location?.host;
  const subDomain = fullPath.split('.')?.[0];
  const currentCity = (props.citiesList || []).find((t) => t.id === subDomain);
  if (!currentItem) {
    return;
  }

  if (currentCity) {
    currentItem.value = currentCity;
  }
};

onMounted(() => {
  initData();
});
</script>

<style lang="scss" scoped>
@import "@/assets/scss/media";

.landing-nsk-desktop {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100vh;
	background: linear-gradient(150deg, #2A134E 22.84%, #3C1D6D 38.85%, #4E2F80 51.98%, #3C1D6D 66.88%, #2A134E 86.07%);

	&::before {
		position: absolute;
		content: '';
		right: 8%;
		top: 4%;
		background: url("~/assets/img/svg/select-city/bg_img.svg") center no-repeat;
		width: 77%;
		height: 85%;
	}
}

.landing-nsk-desktop_text-block {
	position: relative;
	margin-top: 160px;
	margin-left: 16%;

	@include media('xxl') {
		margin-left: 50px;
	}

	& img {
		width: 350px;
		margin-bottom: 20px;
	}

	& .landing-nsk-desktop_desc {
		font-size: 20px;
		line-height: 26px;
		width: 450px;
		color: var(--white);
		margin-bottom: 80px;
		font-family: var(--primary);
	}

}

.landing-nsk-desktop_city-block {
	position: absolute;
	right: 17%;
	top: 41px;
	width: 720px;
	height: 760px;

	@include media('xxl') {
		right: 5%;
	}

	&::before {
		position: absolute;
		content: '';
		left: 0;
		top: 0;
		width: 100%;
		height: 100%;
		background: url("~/assets/img/svg/select-city/city_svg.svg") no-repeat;
	}

	@include media('xl') {
		height: 560px;

		&::before {
			background-position: center;
			background-size: contain;
		}
	}
}

.v-hero-desktop_close {
	position: absolute;
	width: 40px;
	height: 40px;
	top: 100px;
	right: 480px;
	background: var(--secondary);
	box-shadow: -8px 0 16px rgb(60 29 109 / 20%);
	border-radius: 4px 0 0 4px;
	display: flex;
	justify-content: center;
	align-items: center;
	cursor: pointer;

	& img {
		transition: transform 0.4s ease;
	}

	&:hover {
		& img {
			transform: rotate(90deg);
		}
	}
}

.landing-nsk-desktop_choose {
	width: 440px;
	font-weight: 600;
	font-size: 32px;
	line-height: 34px;
	color: var(--secondary);
}

.v-big-close-block {
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	height: 100vh;
	background-color: black;
	opacity: 0.3;
}

.city-item_title {
	position: absolute;
	background: var(--primary-60);
	border-radius: 50px;
	padding: 10px 20px;
	font-size: 14px;
	line-height: 20px;
	text-transform: uppercase;
	color: var(--white);
	font-weight: 600;
	transition: background-color 0.4s ease, color 0.4s ease;
	cursor: pointer;
	text-decoration: none;

	&::before {
		position: absolute;
		content: '';
		top: -30px;
		width: 15px;
		height: 15px;
		background: var(--secondary);
		border-radius: 50%;
		box-shadow: 0 0 20px 5px var(--secondary);
		left: calc(50% - 10px);
		transition: all 0.4s ease;
	}

	&.__active,
	&:hover {
		background: var(--secondary);
		color: var(--primary);

		&::before {
			background: var(--white);
			box-shadow: 0 0 20px 5px var(--white);
		}
	}

	&.city__ekb {
		top: 322px;
		left: 133px;

		@include media('xl') {
			top: 240px;
			left: 175px;
		}
	}

	&.city__msk {
		top: 212px;
		left: -3px;

		@include media('xl') {
			top: 160px;
			left: 82px;
		}
	}

	&.city__tmn {
		top: 387px;
		right: 44px;

		@include media('xl') {
			top: 287px;
			right: 115px;
		}
	}

	&.city__nsk {
		top: 543px;
		right: -43px;

		@include media('xl') {
			top: 385px;
			right: 36px;
		}
	}
}

.landing-nsk-desktop_modal {
	width: 480px;
	height: 100%;
	position: fixed;
	background: var(--white);
	box-shadow: -8px 0 16px rgb(60 29 109 / 20%);
	top: 0;
	right: 0;
	overflow: auto;

	&::before {
		position: absolute;
		content: '';
		width: 470px;
		height: 470px;
		left: -235px;
		top: -235px;
		background: radial-gradient(50% 50% at 50% 50%, #FFC115 0%, rgb(255 193 21 / 0%) 100%);
	}
}

.modal_text-block {
	padding: 100px 40px 0;
	position: relative;

	@include media('xl') {
		padding: 40px;
	}
}

.modal_head-block {
	margin-bottom: 42px;
	display: flex;
	align-items: center;

	& img {
		margin-right: 10px;
		margin-top: -3px;
	}

	& .modal_head-city {
		position: relative;
		font-size: 28px;
		line-height: 33px;
		letter-spacing: 0.07em;
		text-transform: uppercase;
		color: var(--primary);

		&.nsk::before {
			position: absolute;
			content: '';
			width: 34px;
			height: 35px;
			right: -30px;
			top: -20px;
			background: url("~/assets/img/svg/select-city/nsk-icon.svg") center no-repeat;
		}
	}

	@include media('xl') {
		margin-bottom: 15px;
	}
}

.modal_item {
	width: 285px;
	margin-top: 32px;

	@include media('xl') {
		margin-top: 18px;
	}
}

.modal_title-block {
	display: flex;
	justify-content: space-between;
	align-items: center;
}

.modal_title {
	font-family: var(--secondary);
	font-weight: 600;
	font-size: 24px;
	line-height: 26px;
	color: var(--primary);
	margin: 0;
}

.modal_btn-link {
	width: 212px;
	height: 48px;
	background: var(--secondary);
	border-radius: 48px;
	display: flex;
	justify-content: center;
	align-items: center;
	text-decoration: none;
	color: var(--primary);
	font-size: 14px;
	line-height: 20px;
	font-weight: 600;
	transition: all 0.4s ease;

	&:hover {
		opacity: 0.8;
		box-shadow: -8px 0 16px rgb(60 29 109 / 20%);
	}
}

.modal_house-block {
	display: flex;
	flex-wrap: wrap;
	margin-top: 32px;

	& .modal_btn-link {
		padding: 14px 40px;
		margin-right: 16px;
		flex-shrink: 0;
		margin-bottom: 10px;
		width: auto;

		&:nth-child(2n) {
			margin-right: 0;
		}

		&:last-child {
			margin-bottom: 0;
		}
	}

	@include media('xl') {
		margin-top: 20px;
	}
}

.modal_item-title {
	font-weight: 600;
	font-size: 16px;
	line-height: 26px;
	margin-bottom: 8px;
	color: var(--primary);

	@include media('xl') {
		margin: 18px 0 4px;
	}
}

.modal_item-text {
	font-size: 16px;
	line-height: 26px;
	color: var(--primary);

	& a {
		color: var(--primary);
	}
}
</style>
